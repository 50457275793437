.gridContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 4.5rem;
  padding-top: 3rem;
  margin: 0 auto;

    p {
      color: white;
      font-size: 1.5em;
      left: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      line-height: 1.2;
    }
}

.grid > div {
  width: 275px;
  height: 275px;
}

.overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.gridInnerContainer {
  position: relative;
  display: flex;
  text-align: center;
}

@media (max-width: 960px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 650px) {
  .grid {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
  }
}

@media (max-width: 576px) {
  .gridInnerContainer {
    margin: 1rem 0;
  }
}
