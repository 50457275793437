.PhotoGrid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin: 1.5rem;
}

.PhotoGrid_column {
  background-clip: padding-box;
  padding-left: 0.25rem;
}

img {
  width: 100%;
}