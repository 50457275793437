body {
  // @include font-primary;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  overflow-x: hidden;
  width: 100vw;
  background-color: var(--colorNeutral100);
  color: var(--colorNeutral500);
}

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// abstracts

@import './abstracts/colors';
@import './abstracts/variables';

// Pages

@import '../pages/Home';
@import '../pages/activiteiten/Activities';
@import '../pages/contact/Contact';
@import '../pages/404';

// Components

@import '../components/ContentBlock/ContentBlock';
@import '../components/Footer/Footer';
@import '../components/Layout/Layout';
@import '../components/Navigation/Navigation';
@import '../components/PhotoGrid/PhotoGrid';
