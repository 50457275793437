.page_404 {
  margin-top: 3rem;
  text-align: center;
}

.page_404 h3 {
  font-family: 'Adam-Bold', 'Arial', sans-serif;
  font-size: 2.5em;
}

.page_404 a {
  font-weight: bolder;
}