@font-face {
  font-family: 'Adam-Bold';
  src: url('/fonts/Adam-Bold.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Adam-Medium';
  src: url('/fonts/Adam-Medium.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

h1 {
  color: var(--colorNeutral500);
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  font-size: 2em;
  letter-spacing: -0.02em;
  line-height: 0.82;
  margin: 0;
}

h2 {
  font-size: 1.5em;
  color: var(--colorNeutral500);
  font-family: 'Adam-Medium', 'Arial', sans-serif;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

h3 {
  font-size: 1.3em;
  color: var(--colorNeutral500);
  font-family: 'Adam-Medium', 'Arial', sans-serif;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

p {
  font-family: 'Adam-Medium', 'Arial', sans-serif;
  font-size: 1em;
  line-height: 1.75;
}

a,
a:active,
a:visited {
  color: var(--colorNeutral500);
  text-decoration: none;
}

@media (--small) {
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1.05em;
  }

  a:hover {
    text-decoration: none;
  }
}

@media (--medium) {
  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.8em;
  }

  p {
    font-size: 1.1em;
  }
}

@media (--large) {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.15em;
  }
}

@media (--extraLarge) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.2em;
    line-height: 2;
  }
}

@media (--extraExtraLarge) {
  h1 {
    font-size: 3.5em;
  }

  h2 {
    font-size: 1.8em;
  }

  p {
    font-size: 1.3em;
  }
}
