footer {
  padding: 1rem;
  background-color: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer a {
  font-family: 'Adam-Bold', 'Arial', sans-serif;
}

footer p {
  margin: 0;
  line-height: 1.5;
}

footer svg {
  vertical-align: middle;
  width: 1.2rem;
  height: 1.2rem;
}
