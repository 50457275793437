.formContainer {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 75%;
}

.form * {
  font-family: 'Adam-Medium', 'Arial', sans-serif;
}

.Facebook svg {
  vertical-align: middle;
  width: 1.2rem;
  height: 1.2rem;
}

p.formSuccessMessage, p.formErrorMessage {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.2;

  a {
    font-family: 'Adam-Bold', 'Arial', sans-serif;
  }
}

p.formErrorMessage {
  margin: 1rem 0;
  color: red;
}

.form input,
.form textarea {
  padding: 0.5rem;
  border-radius: 0px;
  border: 1px solid $secondaryColor;
  margin-bottom: 0.5rem;
}

.form input::placeholder,
.form textarea::placeholder {
  color: black;
}

.form .inputRow {
  display: flex;
  flex-direction: row;
}

.form .inputRow * {
  flex: 1;
}

.form .inputRow *:first-child {
  margin-right: 5px;
}

.form button {
  font-family: 'Adam-Bold', 'Arial', sans-serif;
  padding: 1rem;
  align-self: flex-end;
  background-color: white;
  border: 1px solid $secondaryColor;
}

@media (max-width: 576px) {
  .form {
    width: 90%;
  }

  .form .inputRow {
    flex-direction: column;
  }

  .form .inputRow *:first-child {
    margin-right: 0;
  }
}
