.Content_Block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.Content_Block_Text {
  width: 35%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: $primaryColor;
  padding: 2rem;
  border: 1rem solid #fff;
  margin-right: -50px;

  a {
    font-family: 'Adam-Medium', 'Arial', sans-serif;
  }
}

.Content_Block_Text h3 {
  padding-bottom: 1rem;
  border-bottom: 1px solid $secondaryColor;
}

.Content_Block_Image {
  width: 35%;
}

.Full_Width .Content_Block_Image {
  width: 45%;
}

.Home .Content_Block_Image {
  margin-top: 300px;
  width: 40%;
}

.activity .Content_Block_Text p {
  font-weight: bold;
}

.Home .Content_Block_Text p {
  font-weight: bolder;
}

.Content_Block_Text p {
  margin: 0;
  font-size: 1em;
  font-family: 'Adam-Medium', 'Arial', sans-serif;

  span {
    font-family: 'Adam-Bold', 'Arial', sans-serif;
  }
}

@media (max-width: $breakpoint-sm) {
  .Content_Block {
    padding-bottom: 1rem 0;
  }
}

@media (max-width: 768px) {
  .Content_Block_Image {
    display: none;
  }

  .Content_Block_Text {
    width: 100%;
    margin: 0;
  }
}
