.Youtube_IFrame {
  height: 580px;
  margin-bottom: 3rem;
}

.Home_Container {
  position: relative;
}

.Home_Container .Tire_Image {
  position: absolute;
  top: 60px;
  right: -150px;
  max-height: 1200px;
  max-width: 1000px;
  z-index: 1;
}

.Home {
  margin-top: -100px;
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .Tire_Image {
    display: none;
  }

  .Home {
    margin: 0.5rem 0;
  }

  .Home_Assets {
    margin: 1rem;
  }
}

.slick-slider {
  overflow: hidden;
}
