.Navigation_Container {
  background-color: $primaryColor;
}

.Navigation_InnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 992px;
  margin: 0 auto;
  padding: 1rem 2rem;
}

ul a:hover,
li.Navigation__Active {
  font-weight: bolder;
}

nav ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 1em;
    text-transform: lowercase;
    font-family: 'Adam-Bold', 'Arial', sans-serif;
  }
}

@media (max-width: $breakpoint-sm) {
  .Navigation_InnerContainer {
    padding-bottom: 0;
    flex-direction: column;
  }
}
